$lavenderGray: #f6f6fc;
$lavenderLight: #f1f0fa;
$white: #ffffff;
$lotion: #fafafa;
$softWhite: #ffffff99;
$ghostWhite: #ffffff80;
$paleBlueGray: #f3f5f9;
$lightGray: #e8e8e8;
$pastelPurple: #e7e4ff;
$darkGrey: #99a8be;
$lavenderPale: #b1aed4;
$lavenderMist: #e7e6eb;
$lightLavender: #dedcf2;
$dustyLavender: #6b677b;
$lightBlueGray: #aab6c9;
$lightAquamarine: #96d7d2;
$ceruleanBlue: #2dbecd;
$cyan: #06fff0;
$darkIndigo: #2e1b57;
$lavenderPurple: #756ebe;
$blueBell: #9b96ce;
$slateBlue: #928ec6;
$disco: #8f1d76;
$vividMagenta: #bc278f;
$electricViolet: #b11bf1;
$mediumRedViolet: #bd268f;
$midnightViolet: #14092d;
$hotPink: #ee3d96;
$darkPink: #ff56b0;
$lightPink: #ef9fb3;
$viola: #cd91bb;
$pinkLace: #ffccf5;
$red: #e61e50;
$lightRed: #f2caca;
$moderateRed: #d25959;
$green: #149b5f;
$limeGreen: #b4dc96;
$lightLimeGreen: #b5f7f0;
$darkLimeGreen: #41b8b4;
$yellow: #ffcb05;
$paleYellow: #ffe9ad;
$lightGrayish: #f1f1f2;
$mistyGray: #f7f7f7b2;
$whisperGray: #f7f7f780;
$athensGray: #f3f4f8;
$cloudMist: #edeff2;
$lilacWhisper: #e4bfe1;
$deepAqua: #218993;
$blueChill: #0fa3a0;
$pastelPink: #ffb1b1;
$darkSalmon: #f47068;
$mediumOrchid: #c65bbb;
$goldFusion: #cca615;
$blue: #0f69af;
$orange: #ea4800;
$lightOrange: #fdb37d;
$lightestOrange: #fdf0e9;
$tango: #ef6921;
$lavenderBlush: #f8e7ff;
$lightGrayishBlue: #dfe4ef;
$palePowderBlue: #dfe4ec;
$mintyTeal: #07aa93;
$paleMagenta: #df80dd;
$black: #000000;
$peachCream: #ffdfce;
$blossomPink: #f2afdd;
$aquaMist: #81d6cc;
$duskyRose: #9b496a;
$blushPink: #efced9;
$radianOrchid: #bd29b3;
$bubblegumPink: #fc92ed;
$electricAqua: #5af3e1;
