// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'colors';
@use 'fonts';
@use 'variables';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$E-LearningPlatformApp-primary: mat.define-palette(mat.$indigo-palette);
$E-LearningPlatformApp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$E-LearningPlatformApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$E-LearningPlatformApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $E-LearningPlatformApp-primary,
      accent: $E-LearningPlatformApp-accent,
      warn: $E-LearningPlatformApp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($E-LearningPlatformApp-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.page-member {
  display: flex;
  min-height: 100vh;
  padding-top: 10px;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(
    282.85deg,
    rgba(177, 27, 241, 0.1) 14.67%,
    rgba(189, 41, 179, 0.1) 38.03%,
    rgba(200, 55, 116, 0.1) 61.4%,
    rgba(239, 105, 33, 0.1) 99.58%
  );
}

body {
  margin: 0;
  font-family: fonts.$global;
  font-style: normal;
  line-height: normal;
}

.merck-font-title {
  color: var(--Purple, #2e1b57);
  font-family: fonts.$global;
  font-size: 36px;
  font-weight: 900;
  line-height: 30px;
}

.sub-title {
  text-align: center;
  color: var(--Purple, #2e1b57);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: fonts.$global;
  font-style: normal;
  line-height: normal;
}

.title-wrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 12px;

  div {
    font-size: 24px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .line {
    width: 100%;
    border: 1px solid #2e1b57;
    margin: 13px 0 20px 20px;
  }
}

//dynamic-text-area
#editor-container {
  display: flex;
  flex-direction: column;
  width: 615px;
}

//dynamic-text-area
.visible-toolbar {
  .ql-toolbar {
    background-color: colors.$white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 30px;
    order: 1;
    border-top: 2px solid #e7e4ff;
    border-bottom: none;
    border-left: none;
    border-right: none;
    width: 613px;
    border-top: colors.$darkIndigo 1px solid !important;

    .ql-picker-label {
      color: colors.$lightGrayishBlue;
    }

    .ql-picker-label:hover {
      color: colors.$darkIndigo !important;
    }

    svg {
      filter: brightness(0) saturate(100%) invert(12%) sepia(11%) saturate(1733%) hue-rotate(241deg) brightness(86%) contrast(92%);
    }

    button:hover,
    button.ql-active {
      svg {
        filter: brightness(0) saturate(100%) invert(12%) sepia(11%) saturate(1733%) hue-rotate(241deg) brightness(86%) contrast(92%);
      }
    }

    button {
      svg {
        filter: none;
      }
    }

    .ql-picker-item,
    .ql-snow.ql-toolbar {
      color: colors.$lightGrayishBlue;
    }

    .ql-picker-item:hover,
    .ql-snow.ql-toolbar:hover {
      color: colors.$darkIndigo;
    }
  }

  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: colors.$darkIndigo !important;
  }

  .ql-snow .ql-stroke,
  .ql-fill {
    stroke: #f1f1f2;
  }

  .ql-container {
    width: 613px;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
  }

  .ql-editor.ql-blank::before {
    color: #2e1b57;
  }

  .ql-editor {
    font-family: Inter, sans-serif;
    color: colors.$darkIndigo;
    font-size: 12px;
    height: 70px;
    background-color: colors.$white;
    overflow-y: hidden;
    order: 2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ql-formats {
    padding-top: 4px;
    margin-top: -12px;
  }

  // Aa
  .ql-toggleCase {
    background-image: url('./assets/images/case_icon.svg') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  // Aa active
  .ql-toggleCase:hover {
    background-image: url('./assets/images/case_icon_active.svg') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

.editor {
  @extend .visible-toolbar;

  .ql-toolbar {
    background-color: colors.$white;
    border: #dfe4ef solid 1px;
  }

  .ql-container {
    .ql-editor {
      background-color: colors.$white;
      border: #dfe4ef solid 1px;
    }
  }
}

//dynamic-text-area
.hidden-toolbar {
  @extend .visible-toolbar;

  .ql-editor {
    border-radius: 5px;
  }

  .ql-toolbar {
    display: none;
  }
}

.hidden-toolbar-v2 {
  @extend .editor;

  .ql-editor {
    border-radius: 5px;
  }

  .ql-toolbar {
    display: none;
  }
}

// e-dynamic-text-area placeholder
.ql-editor.ql-blank::before {
  color: #dfe4ef !important;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  font-family: Inter, sans-serif;
}

// dialog window
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

// e-calendar.component
.mat-date-range-input {
  margin-bottom: 5px;
}

.mat-mdc-text-field-wrapper {
  border-radius: 50px !important;
}

// e-calendar.component
.mat-mdc-form-field {
  font-family: Inter, sans-serif;
  height: 62px;
  // not sure about this
  // padding-top: 0;
  // margin-bottom: -25px;
}

// e-calendar
.mat-calendar-body-cell-content,
.mat-calendar,
.mat-mdc-button {
  font-family: Inter, sans-serif !important;
}

// e-calendar.component
.isActive {
  .mat-calendar-body-cell-content,
  .mat-calendar-body-cell-container:hover {
    .mat-calendar-body-cell-content {
      background-color: #41b8b4 !important;
    }
  }

  .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: #41b8b4;
  }

  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-in-range-state-background-color: #b5f7f0;
  }

  .mat-mdc-text-field-wrapper {
    border: solid 3px transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #5af3e1 0%, #3caa9d 101.75%) border-box;
  }

  .mat-mdc-form-field-flex {
    margin-top: -3px;
  }
}

// e-calendar.component
.isNotActive {
  .mat-calendar-body-cell-content,
  .mat-calendar-body-cell-container:hover {
    .mat-calendar-body-cell-content {
      background-color: #99a8be !important;
    }
  }

  .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: #99a8be;
  }

  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-in-range-state-background-color: #dfe4ef;
  }

  .mat-mdc-text-field-wrapper {
    background-color: colors.$white !important;
  }

  .mat-mdc-form-field-flex {
    margin-top: -1px;
  }
}

// e-calendar.component
.mat-datepicker-content .mat-calendar {
  width: 231px !important;
  height: 285px !important;
}

.mat-datepicker-content {
  border-radius: 20px !important;
}

.mat-datepicker-popup {
  padding: 5px 0;
}

// e-calendar.component
.dueDate {
  .mat-calendar-body-cell-content,
  .mat-calendar-body-cell-container:hover {
    .mat-calendar-body-cell-content {
      background-color: #99a8be !important;
    }
  }

  .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: #99a8be;
  }

  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-in-range-state-background-color: #dfe4ef;
  }

  .input-label,
  .mat-date-range-input-separator,
  .mat-date-range-input {
    color: colors.$darkGrey !important;
  }

  .mat-mdc-text-field-wrapper {
    border: solid 3px transparent;
    background: linear-gradient(#dfe4ef, #dfe4ef) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
  }

  .toggle-button {
    stroke: colors.$darkGrey;
  }

  .mat-mdc-form-field-flex {
    margin-top: -4px;
  }
}

// e-calendar.component
.closeDueDate {
  .mat-calendar-body-cell-content,
  .mat-calendar-body-cell-container:hover {
    .mat-calendar-body-cell-content {
      background-color: #fdb37d !important;
    }
  }

  .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: #fdb37d;
  }

  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-in-range-state-background-color: #fdf0e9;
  }

  .mat-mdc-text-field-wrapper {
    border: solid 3px transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(270deg, #fdb37d 0%, #df80dd 100%) border-box;
  }

  .mat-mdc-form-field-flex {
    margin-top: -3px;
  }
}

// e-calendar.component
.veryCloseDueDate {
  .mat-calendar-body-cell-content,
  .mat-calendar-body-cell-container:hover {
    .mat-calendar-body-cell-content {
      background-color: #fc92ed !important;
    }
  }

  .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: #fc92ed;
  }

  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-in-range-state-background-color: #f8e7ff;
  }

  .mat-mdc-text-field-wrapper {
    border: solid 3px transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #bd29b3 0%, #fc92ed 100%) border-box;
  }

  .mat-mdc-form-field-flex {
    margin-top: -3px;
  }
}

// pop-up togle-button
.mat-mdc-menu-content {
  width: 130px;
  padding: 0 !important;
}

.mat-button-toggle-group-appearance-standard:not([class*='mat-elevation-z']) {
  border: none;
}

// mat-button-toggle view edit
.toggle-dropdown {
  margin-left: -105px;
}

.mat-mdc-menu-panel {
  background-color: colors.$paleBlueGray !important;
}

// pop-up togle-button
.mat-button-toggle-appearance-standard {
  background-color: colors.$paleBlueGray !important;
  color: colors.$darkIndigo !important;
  font-size: 12px;
}

// e-calendar.component
.mdc-floating-label {
  padding-bottom: 16px;
}

// calendar
.mat-calendar-previous-button,
.mat-calendar-next-button,
.mat-calendar-arrow,
.mat-mdc-button:not(:disabled),
.mat-calendar-body-cell-content {
  color: colors.$darkIndigo !important;
}

.mat-calendar-body-label {
  display: none;
}

// e-calendar-component
.mdc-floating-label--float-above {
  transform: translateY(-125%) scale(0.75) !important;
}

// e-calendar-component
.mat-mdc-icon-button svg {
  width: 15px !important;
}

// icons hover effect transparent
.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-icon-button-state-layer-color: transparent;
  --mat-icon-button-ripple-color: transparent;
}

// e-video-player-component
vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow {
  color: #2e1b57;
}

// e-video-player-component
vg-scrub-bar-current-time {
  background-color: #2e1b57 !important;
}

// e-video-player-component
vg-scrub-bar-buffering-time .background {
  background-color: #2e1b57;
}

// e-video-player-component
vg-volume .volumeBackground {
  background-color: #fff !important;
}

// e-video-player-component
vg-volume .volumeValue {
  background-color: #2e1b57 !important;
}

// e-dropdown-component
.dropdown-wrapper,
.hidden-dropdown-wrapper,
.orange-dropdown-wrapper,
.dropdown-wrapper-purple {
  --mat-select-trigger-text-font: Inter, sans-serif;
  width: 243px;
  height: 0;
}

.large-dropdown {
  --mat-select-trigger-text-font: Inter, sans-serif;
  width: 546px;
  height: 40px;
  margin-left: -14px;
}

//large dropdown
.mat-mdc-form-field-icon-suffix {
  margin-bottom: 9px;
}

//large dropdown
.mat-mdc-form-field-type-mat-select.large-dropdown:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    background: linear-gradient(90deg, #fafafa 24%, #ffffff 100%);
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 12px;
    --mat-form-field-state-layer-color: none;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-trigger-text-size: 12px;
    --mat-select-placeholder-text-color: #2e1b57;
    padding-bottom: 39px;
    margin-top: 12px;
    margin-left: 12px;

    mat-select {
      bottom: 16px;
    }
  }

  .mat-mdc-select-value {
    margin-top: 7px;
  }
}

// e-dropdown-component
.dropdown-wrapper-grey {
  --mat-select-trigger-text-font: Inter, sans-serif;
  width: 259px;
  height: 0;
}

// e-dropdown-component
.dropdown-label,
.hidden-dropdown-label {
  width: 96px;
  height: 0px;
  --mat-select-trigger-text-font: Inter, sans-serif;
}

// dropdown-wrapper
.mat-mdc-form-field-type-mat-select.dropdown-wrapper:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 12px;
    --mat-select-trigger-text-size: 12px;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-placeholder-text-color: #2e1b57;
    padding-bottom: 39px;
    margin-top: 0;
    border-radius: 50px;
    background: linear-gradient(90deg, #fafafa 24%, #ffffff 100%) padding-box,
      linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

// dropdown-wrapper-grey
.mat-mdc-form-field-type-mat-select.dropdown-wrapper-grey:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 12px;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-trigger-text-size: 12px;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-placeholder-text-color: #2e1b57;
    padding-bottom: 39px;
    margin-top: 0;
    border-radius: 50px;
    background: linear-gradient(#f3f5f9, #f3f5f9) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

// dropdown-wrapper-purple
.mat-mdc-form-field-type-mat-select.dropdown-wrapper-purple:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 10px;
    --mat-select-trigger-text-size: 10px;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-placeholder-text-color: #2e1b57;
    padding-bottom: 39px;
    margin-top: 0px;
    border-radius: 50px;
    background: linear-gradient(#e7e4ff, #e7e4ff) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

.mat-mdc-select-value {
  overflow: initial !important;
}

// hidden-dropdown-wrapper
.mat-mdc-form-field-type-mat-select.hidden-dropdown-wrapper:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 12px;
    --mat-select-trigger-text-size: 12px;
    --mat-select-enabled-trigger-text-color: #99a8be;
    --mat-select-placeholder-text-color: #99a8be;
    padding-bottom: 39px;
    margin-top: 0;
    border-radius: 50px;
    background: linear-gradient(#dfe4ef, #dfe4ef) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

// orange-dropdown-wrapper
.mat-mdc-form-field-type-mat-select.orange-dropdown-wrapper:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 12px;
    --mat-select-trigger-text-size: 12px;
    --mat-select-enabled-trigger-text-color: #2e1b57;
    --mat-select-placeholder-text-color: #2e1b57;
    padding-bottom: 39px;
    margin-top: 0;
    border-radius: 50px;
    background: linear-gradient(#ffdfce, #ffdfce) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
}

// dropdown-label
.mat-mdc-form-field-type-mat-select.dropdown-label:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 9px;
    --mat-select-enabled-trigger-text-color: #ffffff;
    --mat-select-trigger-text-size: 9px;
    --mat-select-enabled-trigger-text-color: #ffffff;
    --mat-select-placeholder-text-color: #ffffff;
    padding-left: 13px;
    padding-bottom: 27px;
    margin-top: 6px;
    border-radius: 50px;
    background: linear-gradient(#07aa93, #07aa93) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;

    mat-select {
      bottom: 16px;
    }
  }
}

// hidden-dropdown-label
.mat-mdc-form-field-type-mat-select.hidden-dropdown-label:not(.mat-form-field-disabled) {
  .mat-mdc-text-field-wrapper {
    box-shadow: 0px 4px 10.1px 0px #99a8be1f;
    --mat-form-field-state-layer-color: none;
    -webkit-text-fill-size: 9px;
    --mat-select-trigger-text-size: 9px;
    --mat-select-enabled-trigger-text-color: #99a8be;
    --mat-select-placeholder-text-color: #99a8be;
    padding-left: 13px;
    padding-bottom: 27px;
    margin-top: 6px;
    border-radius: 50px;
    background: linear-gradient(#dfe4ef, #dfe4ef) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;

    mat-select {
      bottom: 16px;
    }
  }
}

// e-dropdown-component
.mat-mdc-select-arrow {
  visibility: hidden;
}

// e-dropdown-component
.mdc-line-ripple {
  display: none;
}

// e-dropdown-component
.dropdown-label,
.hidden-dropdown-label,
.dropdown-wrapper-purple {
  .mat-mdc-select {
    font-weight: 700;
  }
}

// e-dropdown-component purple
.panel-wrapper-purple {
  --mat-option-label-text-size: 10px !important;
  --mat-option-label-text-weight: 700;
  --mat-option-label-text-font: Inter, sans-serif;
  border-radius: 50px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

// e-dropdown-component label
.panel-label {
  --mat-option-label-text-size: 9px !important;
  --mat-option-label-text-weight: 700;
  --mat-option-label-text-font: Inter, sans-serif;
  border-radius: 50px;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

// e-dropdown-component wrapper
.panel-wrapper {
  --mat-option-label-text-size: 12px !important;
  --mat-option-label-text-font: Inter, sans-serif;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90.26deg, #ffffff 0.23%, rgba(255, 255, 255, 0.5) 101.58%) border-box;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

// e-dropdown-component
.mat-primary {
  --mat-option-label-text-color: #2e1b57;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2e1b57;
  --mat-option-selected-state-label-text-color: #2e1b57;
}

// e-dropdown-component
div.mat-mdc-select-panel {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 0 !important;
  border-radius: 20px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

// nav-bar
.mat-drawer-container {
  background-color: transparent !important;
}

// edit, delete popup component
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 25px;
  height: 25px;
  padding: 10px 10px 35px 0;
}

// edit, delete popup component
.mat-mdc-button-touch-target {
  width: 1px !important;
  height: 1px !important;
}

// video upload progresss bar
.mat-mdc-progress-bar {
  border-radius: 100px;
  --mdc-linear-progress-active-indicator-color: var(--Purple, #2e1b57);
}

// checkbox label
.mdc-label {
  color: #2e1b57;
}

// e-tabs-component
.p-selectbutton {
  display: flex;
  gap: 1px;
}

// e-tabs-component
.p-component {
  font-size: 8px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  border-radius: 5px;
  border: none;
}

.p-button {
  padding: 0px 7px;
}

// e-tabs-component purple
.toggle-select-purple {
  .p-selectbutton .p-button.p-highlight:hover,
  .p-selectbutton .p-button.p-highlight,
  .p-button:not(:disabled):active {
    min-width: 136px;
    background-color: white;
    color: colors.$darkIndigo;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .p-button:focus {
    box-shadow: none;
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    min-width: 136px;
    background-color: #dedcf2;
    color: colors.$darkIndigo;
    height: 40px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

// e-tabs-component pink
.toggle-select-pink {
  .p-selectbutton .p-button.p-highlight:hover,
  .p-selectbutton .p-button.p-highlight,
  .p-button:not(:disabled):active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 200px;
    background: #f6f6fc;
    color: #2e1b57;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    text-align: center;
  }

  .p-button:focus {
    box-shadow: none;
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 200px;
    background: #dedcf2;
    padding: 14px 17px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #2e1b57;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    text-align: center;
  }
}

.page {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  padding: 10px 0;

  .admin {
    padding: 15px 26px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 100px;
    height: auto;
  }

  padding-bottom: 100px;
}

// e-tabs-component
.p-selectbutton {
  flex-wrap: wrap;
}

// e-tabs-component
.delete-tab {
  .mat-mdc-icon-button img {
    width: 12px !important;
  }
}

.image-tab {
  .mat-mdc-icon-button img {
    width: 22px !important;
  }
}

.img-icon {
  .mat-mdc-icon-button img {
    height: 14px !important;
    width: 14px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 20px;
    padding: 0;
  }
}

.mdc-form-field {
  font-family: Inter, sans-serif !important;
}

//COMMON CSS
.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow-y: hidden;
}

.form-container {
  border-radius: 10px;
  width: 472px;
  background: colors.$white;
  font-family: fonts.$global;
}

.container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal-form-container {
  border-radius: 10px;
  padding: 10px 35px 35px;
  background: colors.$paleBlueGray;
  font-family: fonts.$global;
}

.content-manager {
  padding: 15px 0px 15px 26px;
  height: auto;
  width: 100%;
}

.image-input {
  width: 579px;
  height: 35px;
  padding: 8px 25px 10px 7px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-family: Inter, sans-serif;
  background: #f6f6fc;
  color: #2e1b57;
  font-size: 10px;
  font-weight: 400;
  overflow: hidden;

  .image-text {
    display: inline-flex;
    padding: 0 5px;
    font-size: 8px;
    font-style: italic;
    border-radius: 2px;
    color: colors.$orange;
    background-color: colors.$lightestOrange;
    cursor: pointer;

    .image-file-name {
      display: inline-block;
      max-width: 490px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      filter: brightness(0) saturate(100%) invert(38%) sepia(74%) saturate(5271%) hue-rotate(9deg) brightness(98%) contrast(101%);
      margin-left: 5px;
    }
  }
}

.quiz-picture {
  align-self: center;

  img {
    width: 550px;
  }
}

//TOOLTIP
.tooltip-container {
  position: relative;
  display: inline-block;

  .info-icon {
    filter: brightness(0) saturate(100%) invert(38%) sepia(74%) saturate(5271%) hue-rotate(9deg) brightness(98%) contrast(101%);
  }

  .info-icon:hover {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(21%) sepia(47%) saturate(3841%) hue-rotate(297deg) brightness(101%) contrast(92%);
  }
}

.tooltip-content {
  visibility: hidden;
  width: 200px;
  background-color: colors.$paleBlueGray;
  font-size: variables.$f-size-10;
  color: colors.$darkIndigo;
  font-family: fonts.$global;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 100;
  bottom: 125%;
  /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -100px;
  /* Center the tooltip */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  opacity: 0;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.app-scroll::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
  background-color: colors.$softWhite;
}

.app-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: colors.$vividMagenta;
  border-radius: 100px;
  border-right: none;
  border-left: none;
}
.cdk-overlay-container {
  ::-webkit-scrollbar {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
  }
}

.quiz-container {
  display: flex;
  gap: 15px;
  color: colors.$darkIndigo;
  font-family: fonts.$global;
  font-weight: 400;

  #quiz-picture-wrapper,
  #quiz-question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }

  #quiz-picture-wrapper {
    width: 40%;
    justify-content: center;

    #relative-picture {
      position: relative;

      #question-picture {
        position: relative;
        width: 100%;
        border-radius: 10px;
      }

      #zoom-holder {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: colors.$ghostWhite;
        border-radius: 5px;

        #zoom-image {
          width: 16px;

          &:hover {
            cursor: pointer;
            filter: brightness(0) saturate(100%) invert(9%) sepia(26%) saturate(6851%) hue-rotate(249deg) brightness(96%) contrast(94%);
          }
        }
      }
    }
  }

  #quiz-question-wrapper {
    padding-right: 15px;
  }

  .question-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px;
    font-size: variables.$f-size-16;
    font-weight: 700;

    p {
      margin: 0;
    }

    .info-text {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
    }
  }

  .app-scroll {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 15px;
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Zoom button for answer images.
.zoom-image {
  z-index: 1;
  display: flex;
  position: absolute;
  width: 22px !important;
  margin-top: -30px;
  top: 47px;
  right: 15px;
}

.zoom-image:hover {
  filter: brightness(0) saturate(100%) invert(9%) sepia(26%) saturate(6851%) hue-rotate(249deg) brightness(96%) contrast(94%);
}

.text-container-holder {
  display: flex;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  height: 70px;
  padding: 12px;
  font-family: fonts.$global;
  font-size: 12px;
  font-weight: 700;
}

.tmp-span {
  visibility: hidden;
  white-space: pre;

  // Set styles here for dynamic width use of the tmp-span styles.
  &.module-input,
  &.module-input-italic,
  &.borderless-input,
  &.borderless-input-italic {
    font-family: fonts.$global;
    font-weight: 700;
    line-height: 19px;
    font-size: 16px;
  }
}

#auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
  max-height: 500px;
  padding: 50px 120px;
  font-family: fonts.$global;
  color: colors.$darkIndigo;
  background: linear-gradient(
    282.85deg,
    rgba(177, 27, 241, 0.12) 14.67%,
    rgba(189, 41, 179, 0.12) 38.03%,
    rgba(200, 55, 116, 0.12) 61.4%,
    rgba(239, 105, 33, 0.12) 99.58%
  );
  border-radius: 14px;
  box-shadow: 0px 4px 4px 0px #4e4a4a40;

  #auth-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 24px;

    #auth-title {
      font-weight: 900;
    }

    #auth-subtitle {
      font-weight: 400;
    }
  }

  #auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    #auth-error-message {
      color: colors.$red;
    }
  }
}

.text-area-holder {
  width: 70%;
  max-width: 580px;

  &.explanation {
    max-width: 540px;
  }
}

.select-container {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  border-top: 1px solid colors.$mediumRedViolet;

  .sub-title {
    padding-top: 20px;
    text-align: left;
    font-size: variables.$f-size-10;
    font-weight: 700;
    font-family: fonts.$global;
    margin-bottom: 20px;
  }
}

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  min-height: 100vh;
  padding: 0 105px;
  padding-bottom: 35px;
  font-family: fonts.$global;
  color: colors.$white;
  background-color: colors.$midnightViolet;

  h1 {
    font-family: fonts.$global;
    font-weight: 700;
    letter-spacing: -0.05em;

    &.landing-section-title {
      font-size: 54px;
      line-height: 65.35px;
    }

    &.container-item-title {
      font-size: 24px;
      line-height: 29.05px;
    }
  }

  h2 {
    font-family: fonts.$global;

    &.landing-section-title {
      font-size: 40px;
      font-weight: 700;
      line-height: 48.41px;
      letter-spacing: -0.05em;
    }

    &.container-item-description {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      letter-spacing: -0.05em;
    }
  }

  #landing-header-content {
    display: flex;
    flex-direction: column;
    margin-top: 150px;

    #landing-subtitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 29.05px;
      letter-spacing: -0.05em;
      margin-bottom: 25px;
    }
  }

  #color-cards-container {
    display: flex;
    gap: 15px;

    .card-wrapper {
      display: flex;
      width: 25%;
      border: 1px solid transparent;
      border-radius: 20px;

      .color-card {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        padding: 30px 25px;
        border-radius: 20px;
        backdrop-filter: blur(80px);

        .card-img {
          height: 58px;
        }

        .card-title {
          height: 20%;
          font-size: 24px;
          font-weight: 700;
          line-height: 29.05px;
          letter-spacing: -0.05em;
        }

        .card-description {
          margin-top: 15px;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
        }
      }
    }
  }

  .pink-card {
    background: linear-gradient(180deg, #fc92ed 0%, #847694 100%) padding-box, linear-gradient(98.51deg, #ffffff 12.34%, #df80dd 117.47%) border-box;
  }

  .green-card {
    background: linear-gradient(180deg, #5af3e1 0%, #8492a2 100%) padding-box, linear-gradient(98.51deg, #ffffff 12.34%, #41b8b4 117.47%) border-box;
  }

  .orange-card {
    background: linear-gradient(180deg, #fdb37d 0%, #817283 100%) padding-box, linear-gradient(98.51deg, #ffffff 12.34%, #fdb37d 117.47%) border-box;
  }

  .red-card {
    background: linear-gradient(180deg, #ea4800 0%, #917f88 100%) padding-box, linear-gradient(98.51deg, #ffffff 12.34%, #fdb37d 117.47%) border-box;
  }

  .purple-card {
    background: linear-gradient(180deg, #bd268f 0%, #8a6a92 100%) padding-box, linear-gradient(98.51deg, #ffffff 12.34%, #df80dd 117.47%) border-box;
  }

  .section-holder {
    padding: 55px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid colors.$dustyLavender;
    border-radius: 20px;
    backdrop-filter: blur(50px);

    .information-text {
      width: 50%;
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
      letter-spacing: -0.05em;
    }
  }
}

.team-member-nav-holder {
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    282.85deg,
    rgba(177, 27, 241, 0.1) 14.67%,
    rgba(189, 41, 179, 0.1) 38.03%,
    rgba(200, 55, 116, 0.1) 61.4%,
    rgba(239, 105, 33, 0.1) 99.58%
  );
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding-left: 105px;
  padding-right: 105px;
  border-bottom: 1px solid colors.$darkIndigo;

  .icon-holder {
    display: flex;
    align-items: center;
    cursor: pointer;

    #logo-icon {
      height: 39px;
    }
  }

  .menu-profile-holder {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-left: auto;

    &.landing {
      gap: 75px;
    }

    .link-button {
      font-family: fonts.$global;
      font-size: variables.$f-size-12;
      font-weight: 700;
      color: colors.$darkIndigo;
      text-decoration: none;
      cursor: pointer;
    }

    .link-button:hover {
      text-decoration: underline;
    }
  }
}

.team-member-footer {
  display: flex;
  background-color: colors.$midnightViolet;
  justify-content: space-between;
  height: 173px;
  padding: 25px 75px;
  align-items: stretch;

  .footer-logo-holder {
    width: 33%;

    .links-holder {
      display: flex;
      gap: 10px;
    }

    #footer-img {
      height: 103px;
    }
  }

  .footer-center-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 33%;

    .free-text {
      color: colors.$white;
      text-align: center;
    }

    .powered-by {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      .powered-text {
        color: white;
        font-size: variables.$f-size-12;
        font-weight: 400;
      }
    }
  }

  .other-links-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 10px;
    width: 33%;

    .link {
      width: 90%;
      text-align: right;
    }
  }

  .link {
    color: colors.$white;
    text-decoration: none;
    font-size: variables.$f-size-12;
    font-weight: 400;

    &:not(.other-text):hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
