$f-size-6: 6px;
$f-size-8: 8px;
$f-size-7: 7px;
$f-size-8: 8px;
$f-size-9: 9px;
$f-size-10: 10px;
$f-size-12: 12px;
$f-size-14: 14px;
$f-size-16: 16px;
$f-size-18: 18px;
$f-size-20: 20px;
$f-size-24: 24px;
$f-size-36: 36px;
$f-size-48: 48px;
$f-size-50: 50px;
$f-size-62: 62px;
$f-size-64: 64px;
$f-size-68: 68px;
$landing_max_width: 1350px;
$landing_min_width: 1170px;
